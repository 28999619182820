<template>
  <div id="app">
    <template v-if="isLogin">
      <header class="header">海山日月</header>
      <main class="content">
        <div class="nav">
          <el-menu router :default-active="activeRoute">
<!--              <el-menu-item index="/channel">-->
<!--                <span slot="title">渠道管理</span>-->
<!--              </el-menu-item>-->
<!--              <el-menu-item index="/ad">-->
<!--                <span slot="title">广告管理</span>-->
<!--              </el-menu-item>-->
<!--              <el-menu-item index="/summaryReport">-->
<!--                <span slot="title">大盘报表</span>-->
<!--              </el-menu-item>-->
<!--              <el-menu-item index="/marketReport">-->
<!--                <span slot="title">市场报表</span>-->
<!--              </el-menu-item>-->
              <el-menu-item index="/tickOrder">
                <span slot="title">票务订单</span>
              </el-menu-item>
<!--              <el-menu-item index="/repurchaseReport">-->
<!--                <span slot="title">复购报表</span>-->
<!--              </el-menu-item>-->
<!--              <el-menu-item index="/adAppUser">-->
<!--                <span slot="title">广告用户</span>-->
<!--              </el-menu-item>-->
<!--              <el-menu-item index="/fissionUser">-->
<!--                <span slot="title">裂变用户</span>-->
<!--              </el-menu-item>-->
<!--              <el-menu-item index="/userValue">-->
<!--                <span slot="title">用户资金</span>-->
<!--              </el-menu-item>-->
          </el-menu>
        </div>
        <div class="router-view-container">
          <keep-alive include="Ad">
            <router-view/>
          </keep-alive>
        </div>
      </main>
    </template>
    <template v-else>
      <div class="login-container">
        <h4>海山日月</h4>
        <el-form label-width="60px" label-position="left">
          <el-form-item label="用户名">
            <el-input v-model="loginForm.username"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input type="password" v-model="loginForm.password"></el-input>
          </el-form-item>
          <el-form-item label="验证码">
            <el-input v-model="loginForm.code" style="width: 66px" />
            <img class="code" @click="refreshCode" ref="code" :src="`${basicUrl}/user/captcha`" alt="">
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="login">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </template>
  </div>
</template>
<script>
import api, {basicUrl} from "./api/api"
export default {
  data() {
    return {
      basicUrl,
      // isLogin: true,
      isLogin: localStorage.getItem("isLogin") === "1",

      loginForm: {
        username: "",
        password: "",
        code: ""
      }
    }
  },
  methods: {
    async login() {
      const result = await api.login(this.loginForm.username, this.loginForm.password, this.loginForm.code)
      if (result.code !== 0) {
        this.$message.error(result.msg);
      } else {
        this.isLogin = true
        localStorage.setItem("isLogin", "1");
      }
    },
    async getLoginState() {
      const result = await api.getLoginState()
      if (result.code !== 0) {
        this.$message.error(result.msg);
      } else {
        this.isLogin = result.data
        localStorage.setItem("isLogin", this.isLogin ? "1" : "0");
      }
    },
    refreshCode() {
      this.$refs.code.src = `${basicUrl}/user/captcha?t=${Date.now()}`
    }
  },
  computed: {
    activeRoute() {
      return this.$route.name && ("/" + this.$route.name.toLocaleLowerCase())
    }
  },
  mounted() {
    this.getLoginState();
    setInterval(() => {
      this.getLoginState()
    }, 600 * 1000)
  }
}
</script>

<style>
#app {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#app  > .header {
  font-size: 20px;
  text-align: center;
  padding: 20px;
  border-bottom: solid 1px #e6e6e6;
}

#app  > .content {
  display: flex;
}
#app  > .content .router-view-container {
  padding: 10px 20px;
  flex: 1;
}

.login-container {
  box-sizing: border-box;
  padding: 24px 40px;
  border: 1px solid #d5d6d7;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 340px;
  height: 300px;
  transform: translate(-50%, -74%);
}
.login-container h4{
  margin-bottom: 30px;
  text-align: center;
}

.code {
  position: absolute;
  left: 80px;
}

.fissionUserDetails-container .el-form-item {
  margin-bottom: 0 !important;
}
</style>
