import axios from "axios";

export const basicUrl = "https://yqmt.chenxingent.com/api"
// export const basicUrl = "http://localhost:8088"

async function downloadExcel(url, filename, data) {
    const response = await axios.post(basicUrl + url, data, {
        responseType: "blob"
    })
    const blob = new Blob([response.data], { type: 'application/octet-stream' })
    // 创建新的URL并指向File对象或者Blob对象的地址
    const blobURL = window.URL.createObjectURL(blob)
    // 创建a标签，用于跳转至下载链接
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', decodeURI(filename + ".xls"))
    // 挂载a标签
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    // 释放blob URL地址
    window.URL.revokeObjectURL(blobURL)
}

export default {
    async getLoginState() {
        const result = await axios.get(basicUrl + "/user/isLogin")
        return result.data
    },
    async login(username, password, code) {
        const result = await axios.post(basicUrl + "/user/login", {
            username,
            password,
            code
        })
        return result.data
    },
    async getRepurchaseReports(params) {
        const result = await axios.post(basicUrl + "/repurchase/report", params);

        return result.data
    },
    async getAdSummaries(params) {
        const result = await axios.post(basicUrl + "/summary/adSummaries", params);
        return result.data
    },
    async getMarketReports(params) {
        const result = await axios.post(basicUrl + "/summary/market", params);
        return result.data
    },
    async getSummaryReports(params) {
        const result = await axios.post(basicUrl + "/summary/all", params);
        return result.data
    },
    async getUserSourceOptions() {
        const result = await axios.get(basicUrl +  "/userSourceOption/list")
        return result.data
    },
    async getAdCosts(adId) {
        const result = await axios.get(basicUrl +  "/adCost/list", {
            params: {
                adId
            }
        })
        return result.data
    },
    async addAdCost(adCost) {
        const result = await axios.post(basicUrl +  "/adCost/add", adCost)
        return result.data
    },
    async updateAdCost(adCost) {
        const result = await axios.post(basicUrl +  "/adCost/update", adCost)
        return result.data
    },
    async getVips(curPage, pageSize) {
        const result = await axios.get(basicUrl +  "/hsry/tickOrder/list", {
            params: {
                curPage,
                pageSize
            }
        })
        return result.data
    },
    async downloadReports(filename, reports) {
        const downloadSummaries = reports.map(i => Object.assign({}, i))
        await downloadExcel("/summary/download", filename, {
            downloadSummaries
        })
    },
    async downloadVipUsers() {
        await downloadExcel("/vipUser/download", "vip用户", {})
    },
    async downloadFissionUsers(parentId, fissionUsers) {
        const downloadFissionUsers = fissionUsers.map(i => Object.assign({}, i))
        await downloadExcel("/fissionUser/download", `${parentId}-裂变用户`, {
            appUserSummaries: downloadFissionUsers
        })
    },

    async getAppUserDetails(params) {
        const result = await axios.post(basicUrl +  "/adAppUser/list", params)
        return result.data
    },

    async getFissionUserDetails(parentId) {
        const result = await axios.get(basicUrl +  "/fissionUser/list", {
            params: {
                parentId
            }
        })
        return result.data
    },
    async getAdUserBalance(params) {
        const result = await axios.post(basicUrl +  "/userBalance/ad", params)
        return result.data
    },
    async getUserBalance() {
        const result = await axios.get(basicUrl +  "/userBalance/all")
        return result.data
    },
    async getAdUserUserItemTotalValue(params) {
        const result = await axios.post(basicUrl +  "/userItem/ad", params)
        return result.data
    },
    async getUserItemTotalValue() {
        const result = await axios.get(basicUrl +  "/userItem/all")
        return result.data
    },
    async getTraderUserItemTotalValue() {
        const result = await axios.get(basicUrl +  "/userItem/trader")
        return result.data
    },
    async test1() {
        const result = await axios.get(basicUrl +  "/applyTicket/list", {
            headers: {
              cxt: "ejw+O~TMFe6Vh&QA%vbw(8dI"
            },
            params: {
                type: 2,
                startDate: "2022-07-20",
                endDate: "2022-08-20",
                curPage:1,
                pageSize: 20
            }
        })
        return result.data
    },
    async test2() {
        const result = await axios.get(basicUrl +  "/applyTicket/get/23", {
            headers: {
                cxt: "ejw+O~TMFe6Vh&QA%vbw(8dI"
            }
        })
        return result.data
    },
}
