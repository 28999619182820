import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    redirect: { name: 'TickOrder' }
  },
  // {
  //   path: '/channel',
  //   name: 'Channel',
  //   component: () => import(/* webpackChunkName: "channel" */ '../views/Channel.vue')
  // },
  // {
  //   path: '/ad',
  //   name: 'Ad',
  //   component: () => import(/* webpackChunkName: "atd1" */ '../views/Ad.vue')
  // },
  // {
  //   path: '/adCost/:id',
  //   name: 'AdCost',
  //   component: () => import(/* webpackChunkName: "atd2" */ '../views/AdCost.vue')
  // },
  // {
  //   path: '/marketReport',
  //   name: 'MarketReport',
  //   component: () => import(/* webpackChunkName: "marketReport" */ '../views/MarketReport.vue')
  // },
  // {
  //   path: '/summaryReport',
  //   name: 'SummaryReport',
  //   component: () => import(/* webpackChunkName: "summaryReport" */ '../views/SummaryReport')
  // },
  {
    path: '/tickOrder',
    name: 'TickOrder',
    component: () => import(/* webpackChunkName: "vipUser" */ '../views/VipUser')
  },
  // {
  //   path: '/repurchaseReport',
  //   name: 'RepurchaseReport',
  //   component: () => import(/* webpackChunkName: "repurchaseReport" */ '../views/RepurchaseReport')
  // },
  // {
  //   path: '/adAppUser',
  //   name: 'AdAppUser',
  //   component: () => import(/* webpackChunkName: "adAppUser" */ '../views/AdAppUser')
  // },
  // {
  //   path: '/fissionUser',
  //   name: 'FissionUser',
  //   component: () => import(/* webpackChunkName: "fissionAppUser" */ '../views/FissionAppUser')
  // },
  // {
  //   path: '/userValue',
  //   name: 'UserValue',
  //   component: () => import(/* webpackChunkName: "userValue" */ '../views/UserValue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
